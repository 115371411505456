﻿/* global tumarket, LiveTex */

import * as $ from "jquery";

import tumGlobal from "./global.js";
import { showDialog, closeDialog, showAlert } from "./global/dialog";
import { ajaxSend, request } from "./global/fetch";
import { bootstrapAlert } from "./global/alert";
import { getUrlParameter } from "./global/url";
import { getCookie, setCookie } from "./global/cookie";
import { loadLib } from "./global/loadlib";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "./global/templates";
import { initPopovers } from "./global/popover";
import { addEventDelegate, documentHeight, setValue, toggleClass } from "./global/dom";
import { isMob } from "./global/util";
import { searchWidgets } from "./navbar/searchSelect.js";
import { setToggle } from "./global/toggle.js";

function _setTimeout(ms) {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
}

function showAdminOnlyStuff(){
	if (tumarket.isAdmin) {
		for (let el of document.querySelectorAll('[data-admin-only]')){
			toggleClass(el, 'd-none', false);
		}
	}
}

function toggleExtendedView(code) {
	if (code == "enable") {
		toggleClass(".tum-enableExtendedView i", "fa-toggle-on", true);
		toggleClass(".tum-enableExtendedView i", "fa-toggle-off", false);
	}
	else if (code == "disable") {
		toggleClass(".tum-enableExtendedView i", "fa-toggle-on", false);
		toggleClass(".tum-enableExtendedView i", "fa-toggle-off", true);
	}
	else {
		toggleClass(".tum-enableExtendedView i", "fa-toggle-off fa-toggle-on");
	}

	if (document.querySelector(".tum-enableExtendedView .fa-toggle-on")) {
		setCookie("extended_view", "1", 30);
	}
	else {
		setCookie("extended_view", "", 30);
	}
}

let searchIconBlinked = false;

var tumCommon = {
	options: {
		hideFloatingHeader: false
	},
	init: function (options) {
		Object.assign(this.options, options);
		this.initCallbacks();

		this.checkAuthorization();
		this.updateCartInfo();
		this.checkCookies();
		this.initHeader();
		this.initFloatingHeader();
		this.initCtuCloud();
		this.initFloatingWindows();
		this.initCacheControls();
		this.initOperatorControls();
		this.initMobSearchBar();

		initPopovers();

		addEventDelegate('click', '.nobubble', (element, event) => {
			event.stopPropagation();
		});
	
		addEventDelegate(document, 'click', '.copyToClipboard', element => {
			let content = element.dataset.content;
			tumGlobal.copyToClipboard(content);
			bootstrapAlert('Скопировано в буфер обмена', { type: 'success' });
		});

		document.querySelector('[data-telegram-connect-suggestion-hide]')?.addEventListener('click', () => {
			setCookie('hideTelegramMsg', '1', 1/24);
			document.querySelector('[data-telegram-connect-suggestion]').remove();
		});

		addEventDelegate(document, 'click', '[data-dismiss="dropdown"]', element => {
			$(element.closest('.dropdown')).dropdown('hide');
		});

		showAdminOnlyStuff();

		addEventDelegate(document, 'click', '[data-clear-cache]', element => {
			ajaxSend({
				url: '/requests/clearcache',
				data: {
					prefix: element.dataset.prefix,
					firmId: element.dataset.firmId
				}
			}).then(data => {
				if (data.result) bootstrapAlert('Кеш сброшен', { type: 'success' });
				else bootstrapAlert('Ошибка', { type: 'danger' });
			});
		});

		addEventDelegate(document, 'click', 'a.disabled', (element, event) => {
			event.preventDefault();
		});

		addEventDelegate(document, 'click', '.tu-toggle-disabled, .tu-toggle.disabled', (element, event) => {
			event.preventDefault();
		});
	},
	initMobSearchBar: function(){
		let lastScrollTop = 0;
		let lastState = false;
		let mobSearchBar = document.querySelector('.mob-search-bar');
		if (!mobSearchBar) return;

		window.addEventListener("scroll", () => {
			let st = window.scrollY || document.documentElement.scrollTop;

			let show = lastState;
			let canBeShown = this.showFloatingHeader_desktop('.mob-search-bar');

			if (st > lastScrollTop) {
				show = false;
			} else if (st < lastScrollTop) {
				show = canBeShown;
			}

			lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
			lastState = show;

			toggleClass(mobSearchBar, 'show', show);
			toggleClass('body', 'mob-search-bar-shown', show);
		}, { passive: true });
	},
	initFloatingHeader: function(){
		var self = this;
		if (!tumGlobal.isMob()) {
			window.addEventListener('scroll', () => {
				let showFloatingHeader = self.showFloatingHeader_desktop();
				toggleClass('.header-floating', 'show', showFloatingHeader);
				toggleClass('body', 'floating-header-shown', showFloatingHeader);

				if (document.querySelector('.header-floating.top')) {
					toggleClass('body', 'floating-header-shown-top', showFloatingHeader);
				}
			}, { passive: true });

			return;
		}

		window.addEventListener('scroll', () => this.updateFloatingHeader(), { passive: true });
		window.dispatchEvent(new Event('scroll'));
	},
	updateFloatingHeader: function(){
		let showFloatingHeader = this.showFloatingHeader_desktop();
		toggleClass('.header-floating.bottom', 'show', showFloatingHeader && this.showFloatingHeader_mob() && !this.options.hideFloatingHeader);
		toggleClass('.header-floating.top', 'show', showFloatingHeader);

		toggleClass('body', 'floating-header-shown', showFloatingHeader);
		if (document.querySelector('.header-floating.top')) {
			toggleClass('body', 'floating-header-shown-top', showFloatingHeader);
		}
	},
	toggleTopBar: function(){
		toggleClass('.topbar.main', 'd-none');
	},
	toggleFloatingHeader: function(){
		this.options.hideFloatingHeader = !this.options.hideFloatingHeader;
	},
	showFloatingHeader_desktop: function(selector){
		let header = document.querySelector(selector || '.header-floating');
		if (!header) return false;
		return window.scrollY > header.clientHeight;
	},
	showFloatingHeader_mob: function(){
		let header = document.querySelector('.header-floating');
		if (!header) return false;
		return window.scrollY < (documentHeight() - window.innerHeight - header.clientHeight * 3);
	},
	initCtuCloud: function(){
		let containers = document.querySelectorAll('.ctuCloud');
		for (let cloud of containers) {
			if (cloud.scrollWidth <= cloud.offsetWidth) return;
			
			let $back = cloud.querySelector('.ctuCloud-back');
			let $forward = cloud.querySelector('.ctuCloud-forward');
			$forward?.classList.remove('d-md-none');
			$forward?.classList.add.addClass('d-md-block');

			let maxScrollLeft = () => cloud.scrollWidth - cloud.offsetWidth;
			let updateButtons = () => {
				let showBack = cloud.scrollLeft > 0;
				let showForward = cloud.scrollLeft < maxScrollLeft();
				toggleClass($back, 'd-md-none', !showBack);
				toggleClass($back, 'd-md-block', showBack);
				toggleClass($forward, 'd-md-none', !showForward);
				toggleClass($forward, 'd-md-block', showForward);
			};
			
			$back?.on('click', () => {
				$(cloud).animate({ scrollLeft: Math.max(0, cloud.scrollLeft - cloud.offsetWidth)}, 200, null, updateButtons);
			});
			
			$forward?.on('click', () => {
				$(cloud).animate({ scrollLeft: Math.min(maxScrollLeft(), cloud.scrollLeft + cloud.offsetWidth)}, 200, null, updateButtons);
			});
		}
	},
	initCacheControls: function(){
		addEventDelegate(document, 'click', '.tum-toggleCache', () => {
			var disableCache = getCookie("disableCache");
			if (disableCache == "1") {
				setCookie("disableCache", "0", 1/24/2);
			}
			else {
				setCookie("disableCache", "1", 1/24/2);
			}
			this.updateCacheToggle();
			location.reload();
		});

		addEventDelegate(document, 'click', '.tum-toggleCache-long', () => {
			var disableCache_long = getCookie("disableCache_long");
			if (disableCache_long == "1") {
				setCookie("disableCache_long", "0", 1);
			}
			else {
				setCookie("disableCache_long", "1", 1);
			}
			this.updateCacheToggle();
			location.reload();
		});
	},
	initOperatorControls: function(){
		addEventDelegate(document, 'click', '.tum-operatorAddonToggle', () => {
			var enable = getCookie("enableOperatorAddon");
			if (enable == "1") {
				setCookie("enableOperatorAddon", "0", 1);
			}
			else {
				setCookie("enableOperatorAddon", "1", 1);
			}
			this.updateOperatorAddonToggle();
			location.reload();
		});
	},
	loadLivetex: function(callback) {
		if (window.LiveTex) {
			callback();
			return;
		}

		window.liveTex = true;
		window.liveTexID = 170736;
		window.liveTex_object = true;
		window.LiveTex = {
			onLiveTexReady: function () {
				LiveTex.hideLabel();
				callback();
			}
		};

		loadLib({
			jsLink: "https://cs15.livetex.ru/js/client.js",
			conditionObject: window._LiveTex
		});
	},
	initCallbacks: function () {
		var self = this;

		addEventDelegate(document, 'click', '[data-set-cookie]', element => {
			setCookie(element.dataset.name, element.dataset.value, element.dataset.duration / 24);
		});
	
		addEventDelegate(document, 'change', '[data-set-input-value]', element => {
			setValue(element.dataset.target, element.value);
		});

		addEventDelegate(document, 'click', '[data-tum-toggle]', element => {
			setToggle(element);
		});

		addEventDelegate(document, 'click', '[data-scrollup]', () => {
			tumGlobal.scrollUp();
		});

		addEventDelegate(document, 'click', '.tum-collapse-link', element => { this.collapseLinkClick($(element)) });
		addEventDelegate(document, 'click', '.tum-collapse-link2', element => { this.collapseLinkClick2($(element)) });
		
		addEventDelegate(document, 'click', '.tum-collapse-link-info', element => { this.collapseLinkInfoClick($(element)) });
		addEventDelegate(document, 'resize', 'body', () => { this.checkPageHeight() });

		addEventDelegate(document, 'click', '.tu-getShortUrl', element => { 
			let url = $(element).attr('data-url');
			this.getShortUrl(url);
		});

		self.checkPageHeight();

		addEventDelegate(document, 'click', ".tum-seofooter-hide", () => {
			toggleClass(".tum-seofooter", 'd-none');

			let shown = !$('.tum-seofooter-content').hasClass('d-none');
			this.setSeoFooterCookie(shown ? 0 : 1);
		});

		addEventDelegate(document, 'click', '.show-search-menu', () => {
			import("./searchMenu").then(({tum_searchMenu}) => {
				tum_searchMenu.toggleMenu('show').then(() => {
					tum_searchMenu.focusSearch();
				});
			});
		});

		addEventDelegate(document, 'click', '.tum-focusSearch, .tu-show-searh, .show-search-menu-focus, .b-catalog-find', element => {
			if (!isMob()) {
				tumGlobal.scrollUp();
			}

			this.showSearchTypes(element.dataset.code, element.dataset.startSearch == '');
		});

		//клик по ридмору или меню в миниатуюре ТУ или КА подсвечивает краточку
		addEventDelegate(document, 'click', ".product-card, .firm-miniature", element => {
			$(element).addClass("important-border");
		});

		//подсказки для доставки по миниатюрам ТУ
		addEventDelegate(document, 'click', '.tum-isFix-hint-deliv', element => {
			var text = $(element).attr("data-content");
			var elem = $(element).closest(".hasHint_deliv");
			if ($(element).hasClass("d-none")) {
				this.showHintDeliv(text, elem, true);
				return;
			}
			this.showHintDeliv(text, elem);
		});
		
		addEventDelegate(document, 'click', ".hideHintDeliv", (element, e) => {
			e.preventDefault();
			$(element).closest(".tum-hint-block-deliv").addClass("d-none");
		});

		self.defineSearchType();
		self.defineSearchOpen();

		addEventDelegate(document, 'click', '.operatorChat', () => {
			this.loadLivetex(() => LiveTex.showWelcomeWindow());
		});

		addEventDelegate(document, 'click', '.tu-showFirmOnMap', (element, e) => {
			e.stopPropagation();
			self.showFirmOnMap({
				lat: $(element).attr('data-lat'),
				long: $(element).attr('data-long'),
				firmID: $(element).attr('data-firmid')
			});
		});
		
		addEventDelegate(document, 'click', '.tu-editUpdateFailureNotificationContacts', element => {
			this.editFailureNotificationContacts($(element).attr('data-firmid'));
		});

		addEventDelegate(document, 'click', '.tu-scrollUp', () => tumGlobal.scrollUp());

		addEventDelegate(document, 'click', '[data-wt-update]', element => {
			let firmID = $(element).attr('data-firmid');

			ajaxSend({ url: '/firmRequests/UpdateProductWeights', data: { firmID }}).then(data => {
				if (data.result) {
					let msg = $('[data-wt-update-success-msg]').html();
					showAlert(msg, { type: 'success2' });
					$('[data-wt-update-container]').remove();
				} else {
					let msg = $('[data-wt-update-failure-msg]').html();
					showAlert(msg, { type: 'danger2' });
				}
			});
		});

		addEventDelegate(document, 'click', ".tum-enableExtendedView", () => {
			toggleExtendedView();
		});
	},
	showSearchTypes: function(code, startSearch){
		if (!tumGlobal.isMob()){
			searchWidgets.main.focus();
			searchWidgets.main.toggleSearchTypes('show');

			if (code) searchWidgets.main.setSearchType(code);
			if (code && startSearch && searchWidgets.main.getQuery()) {
				searchWidgets.main.search(searchWidgets.main.getSearchType().type, searchWidgets.main.getQuery());
			}
		} else {
			import("./searchMenu").then(({tum_searchMenu}) => {
				tum_searchMenu.toggleMenu('show')
				.then(() => {
					tum_searchMenu.focusSearch();
					tum_searchMenu.toggleSearchTypes('show');

					if (code) tum_searchMenu.setSearchType(code);
					if (code && startSearch && tum_searchMenu.getSearchQuery()) {
						tum_searchMenu.search();
					}
				});
			});
		}
	},
	checkCookies: function(){
		function showNoCookiesWarning(){
			$('.tum-noCookies-warning').removeClass('d-none');
		}

		var cookieEnabled = navigator.cookieEnabled;
		if (!cookieEnabled){ 
			setCookie('testcookie', "123", 1);
			cookieEnabled = getCookie("testcookie") == "123";
			if (cookieEnabled) {
				setCookie('testcookie', '0', -1);
			}
		}
		return cookieEnabled || showNoCookiesWarning();
	},
	updateCartInfo: function(){
		if (location.pathname.startsWith("/signup")) {
			import("./login.js").then(({ reg }) => {
				$(`input[name=saveCartItems][value="${(reg.options.saveCartItems ? "1" : "0")}"]`).prop("checked", true);
			});
		}
	},
	updateCacheToggle: function(){
		var disableCache = getCookie("disableCache");
		if (disableCache == "1") {
			$(".tum-toggleCache i").removeClass("fa-toggle-off").addClass("fa-toggle-on");
		}
		else {
			$(".tum-toggleCache i").removeClass("fa-toggle-on").addClass("fa-toggle-off");
		}

		var disableCache_long = getCookie("disableCache_long");
		if (disableCache_long == "1") {
			$(".tum-toggleCache-long i").removeClass("fa-toggle-off").addClass("fa-toggle-on");
		}
		else {
			$(".tum-toggleCache-long i").removeClass("fa-toggle-on").addClass("fa-toggle-off");
		}
	},
	updateOperatorAddonToggle: function(){
		var enable = getCookie("enableOperatorAddon");
		if (enable == "1") {
			$(".tum-operatorAddonToggle i").removeClass("fa-toggle-off").addClass("fa-toggle-on");
		}
		else {
			$(".tum-operatorAddonToggle i").removeClass("fa-toggle-on").addClass("fa-toggle-off");
		}
	},
	collapseLinkClick: function (elm) {		
		elm.toggleClass('d-none');
	},
	collapseLinkInfoClick: function (elm) {
		var selector = $(elm).attr('data-main-link');
		$(selector).removeClass('d-none');
	},
	collapseLinkClick2: function (elm) {
		var id = elm.closest('div').attr('id');
		setTimeout(function () {$('a.tum-collapse-link[href="#' + id + '"]').removeClass('d-none'); }, 100);
		
	},
	checkPageHeight: function () {
		if ($('body')[0].scrollHeight < window.innerHeight) {
			$('body').addClass('stuck-bottom');
			$('.b-footer').addClass('stuck-bottom');
		} else {
			$('body').removeClass('stuck-bottom');
			$('.b-footer').removeClass('stuck-bottom');
		}
	},
	//устанавливает тип поиска если таковой был передан
	defineSearchType: function () {
		if (getUrlParameter("search-type")) {
			var searchType = getUrlParameter("search-type");
			$("[data-search-type='" + searchType + "']").first()[0]?.click();
		}
	},
	//открываем поиски если был передан параметр
	defineSearchOpen: function () {
		if (getUrlParameter("search-open") == "1") {
			this.showSearchTypes();
		}
	},
	showHintDeliv: function (txt, elem, close) {
		var hintBlock = $(elem).find(".tum-hint-block-deliv");
		if (close) {
			hintBlock.addClass("d-none");
			return;
		}
		hintBlock.removeClass("d-none");
		hintBlock.html('<div class="d-flex justify-content-between"><span><i class="fa fa-question-circle hintIcon" aria-hidden="true"></i> <a href="#" rel="nofollow" class="hideHintDeliv">Закрыть</a></span><a href="javascript:void(0)" class="hideHintDeliv"><i class="fas fa-times tum-grey"></i></a></div>' + txt);
		hintBlock.addClass('hintBorder');
	},
	checkAuthorization: function(){
		let isAuthorized = document.querySelector(".t-authorized")?.innerHTML == "True";

		if (isAuthorized) {
			ajaxSend({ url: "/home/checkAuthorization" }).then(data => {
				if (!data.result) {
					location.reload();
				}
			});
		}
	},
	getShortUrl: function (url) {
		ajaxSend({ url: '/shortLink/getShortUrl', data: { url: url || location.href } }).then(data => {
			var title = 'Ссылка для товаров и услуг';
			var html = `Скопировано в буфер обмена <a class="fas fa-external-link-alt" href="${data.result}" target="_blank"></a><hr>
						<small class="tum-grey">${data.result}</small>`;

			tumGlobal.copyToClipboard(data.result);

			showDialog({
				title: title,
				content: html,
				onShown: () => $('#asModal .btn-primary').remove()
			});
		});
	},
	showFirmOnMap: function(options){
		let self = this;

		import("./map.js").then(({ tu_maps }) => {
			showDialog({
				title: 'На карте',
				content: '<div id="menu-map-cont" class="tu-map" style="width: 100%; height: 400px"></div>',
				onShown: () => {
					$('.btn-primary', '#asModal').addClass('d-none');
	
					self.currentMap = tu_maps.create({
						containerId: 'menu-map-cont',
						center: {
							lat: options.lat,
							long: options.long
						},
						height: null,
						zoom: 16,
						onLoad: (ymap, modules, map) => {
							let placemark = new modules.Placemark([options.lat, options.long]);
	
							function showBalloon() {
								map.getBalloonContent(options.firmID, 0, true)
									.then(e => map.showCustomBalloon(e, true));
							}
	
							placemark.events.add('click', function () {
								showBalloon();

								import("./statistics.js").then(({ tumStats }) => {
									tumStats.logEvent({
										firmID: options.firmID,
										objectType: 'map',
										eventType: 'click_showContacts'
									});
								});
							});
	
							ymap.geoObjects.add(placemark);
							showBalloon();
						}
					});
				},
				onClosed: () => {
					$('#asModal .btn-primary').removeClass('d-none');
					tu_maps.dropSettingsModal();
				}
			});
		});
	},
	_getSearchBarTop: function(){
		let searchbar = document.querySelector('.searchWidget');
		return searchbar.getBoundingClientRect().top + window.scrollY;
	},
	updateSearchMenuButton: function(){
		let searchBarTop = this._getSearchBarTop();
		let searchMenuButton = document.querySelector('.topbar .toggleSearchMenu');

		let show = window.scrollY >= searchBarTop - 20;

		if (!searchIconBlinked) {
			_setTimeout(333).then(() => this.blinkSearchIcon());
		}

		let active = searchMenuButton.matches('.active');
		let shown = searchMenuButton.matches('.show');

		//если иконка сейчас мигает, то ее можно скрыть, но нельзя "показать", иначе ломается анимация
		if (active && !shown) {
			if (!show) {
				toggleClass(searchMenuButton, 'show active', show);	
			}

			return;
		}

		toggleClass(searchMenuButton, 'show active', show);
	},
	initHeader: function(){
		setInterval(() => this.blinkSearchIcon(), 10000);
		if (!tumGlobal.isMob() || !searchWidgets.main.exists()) {
			let $searchMenuButton = $('.topbar .toggleSearchMenu');
			$searchMenuButton.addClass('show active');
			return;
		} 
		
		//кнопка меню поиска появляется, только когда поле поиска на странице скрывается с экрана
		this.updateSearchMenuButton();
		$(document).on('scroll', () => this.updateSearchMenuButton());
	},
	blinkSearchIcon: function(){
		let searchMenuButton = document.querySelector('.topbar .toggleSearchMenu');
		if (!searchMenuButton?.matches('.active')) return;

		let delay = 333;
		
		toggleClass(searchMenuButton, 'show', false);
		_setTimeout(delay)
		.then(() => toggleClass(searchMenuButton, 'show', true))
		.then(() => _setTimeout(delay))
		.then(() => toggleClass(searchMenuButton, 'show', false))
		.then(() => _setTimeout(delay))
		.then(() => {
			searchIconBlinked = true
			
			//проверка на случай, если за время анимации иконка скрылась
			if (searchMenuButton.matches('.active')) {
				toggleClass(searchMenuButton, 'show', true);
			}
		});
	},
	getCartCount: function(){
		return new Promise((resolve, reject) => {
			ajaxSend({ url: '/home/getCartCount' }).then(data => {
				if (data.result) resolve(data.count);
				else reject('getCartCount error');
			});
		});
	},
	getCartSum: function(){
		return new Promise((resolve, reject) => {
			ajaxSend({ url: '/home/getCartSum' }).then(data => {
				if (data.result) resolve(data.sum);
				else reject('getCartSum error');
			});
		});
	},
	getFavoritesCount: function(){
		return new Promise((resolve, reject) => {
			ajaxSend({ url: '/home/getFavoritesCount' }).then(data => {
				if (data.result) resolve(data.count);
				else reject('getFavoritesCount error');
			});
		});
	},
	getNotificationsCount: function(){
		return new Promise((resolve, reject) => {
			ajaxSend({ url: '/home/getNotificationsCount' }).then(data => {
				if (data.result) resolve(data.count);
				else reject('getNotificationsCount error');
			});
		});
	},
	_updateCartCount: function(){
		let $count = $('.topbar .cart-count');
		$count.html(tumarket.general.cartCount);
		$count.toggleClass('d-none', tumarket.general.cartCount == 0);
	},
	_updateCartSum: function(){
		let $cart = $('.topbar .topbar-cart');
		$cart.attr('title', tumarket.general.cartSum > 0 ? `Сумма: ${tumarket.general.cartSum.toFixed(2)}р` : null)
		$cart.toggleClass('d-none', tumarket.general.cartSum == 0);
	},
	_updateFavoritesCount: function(){
		let $count = $('.topbar .favorites-count');
		$count.html(tumarket.general.favoritesCount);
		$count.toggleClass('d-none', tumarket.general.favoritesCount == 0);
	},
	_updateNotificationsCount: function(){
		let $count = $('.topbar .notifications-count');
		$count.html(tumarket.general.notificationsCount);
		$count.toggleClass('d-none', tumarket.general.notificationsCount == 0);
	},
	updateCartCount: function(){
		this.getCartCount()
		.then(count => {
			tumarket.general.cartCount = count;
			this._updateCartCount();
		});
	},
	updateCartSum: function(){
		this.getCartSum()
		.then(sum => {
			tumarket.general.cartSum = sum;
			this._updateCartSum();
		});
	},
	updateFavoritesCount: function(){
		this.getFavoritesCount()
		.then(count => {
			tumarket.general.favoritesCount = count;
			this._updateFavoritesCount();
		});
	},
	updateNotificationsCount: function(){
		this.getNotificationsCount()
		.then(count => {
			tumarket.general.notificationsCount = count;
			this._updateNotificationsCount();
		});
	},
	isHeaderFloating: function(){
		return $('.header-floating').hasClass('show');
	},
	getFirmSettings: function(firmId) {
		return new Promise((resolve, reject) => {
			ajaxSend({ url: '/firmRequests/getFirmSettings', data: { firmId } }).then(data => {
				if (data.result) resolve(data.value);
				else reject(data.msg);
			});
		});
	},
	editFailureNotificationContacts: function(firmId) {
		Promise.all([
			this.getFirmSettings(firmId),
			this.getUserRole(firmId),
			loadPrecompiledTemplates(['updateFailureNotificationContacts-form'])
		])
		.then(results => {
			let form = null;
			let formJson = getPrecompiledTemplate('updateFailureNotificationContacts-form')();
			let formFields = JSON.parse(formJson);
			let formData = JSON.parse(results[0].updateFailureNotificationContacts || '{}');

			showDialog({
				title: 'Контакты при сбоях в обновлении',
				content: '<div class="contacts-form"></div>',
				buttonText: 'Сохранить',
				onShown: () => {
					import("./form/form.js").then(({ tu_form }) => {
						tu_form.create({
							container: '.contacts-form',
							fields: formFields,
							data: formData,
							userRoles: [results[1]]
						})
						.then(f => form = f);
					});
				},
				onSubmit: () => {
					if (!form) return;

					if (!form.validate()) {
						bootstrapAlert('Не все поля заполнены корректно', { type: 'danger' });
						return;
					}

					let data = form.getData();

					function alert(){
						bootstrapAlert('Контакт с выбранным приоритетом должен быть заполнен', { type: 'danger' });
					}

					if (data.tech.priority == 'phone' && !data.tech.phone) {
						alert();
						form.addHighlight({ name: 'tech', field: 'phone' });
						return;
					}

					if (data.tech.priority == 'email' && !data.tech.email) {
						alert();
						form.addHighlight({ name: 'tech', field: 'email' });
						return;
					}

					if (data.org.priority == 'phone' && !data.org.phone) {
						alert();
						form.addHighlight({ name: 'org', field: 'phone' });
						return;
					}

					if (data.org.priority == 'email' && !data.org.email) {
						alert();
						form.addHighlight({ name: 'org', field: 'email' });
						return;
					}

					this.updateFirmSettings(firmId, { updateFailureNotificationContacts: JSON.stringify(data) })
					.then(() => {
						bootstrapAlert('Сохранено', { type: 'success' });
						closeDialog();
					})
					.catch(msg => bootstrapAlert(msg || 'Ошибка', { type: 'danger' }));
				}
			});
		})
		.catch(msg => showAlert(msg || 'Ошибка', { type: 'danger2' }))
	},
	updateFirmSettings: function(firmId, settings) {
		settings.firmId = firmId;
		return new Promise((resolve, reject) => {
			request('firm-settings-update', settings).then(data => {
				if (data.result) resolve();
				else reject(data.msg);
			});
		});
	},
	checkPermissions: function(firmID){
		return new Promise((resolve, reject) => {
			ajaxSend({ url: '/firmRequests/checkPermissions', data: { firmID } }).then(data => {
				if (data.result) resolve(data.value);
				else reject(data.msg);
			});
		});
	},
	getUserRole: function(firmID){
		return new Promise((resolve, reject) => {
			ajaxSend({ url: '/firmRequests/getUserRole', data: { firmID } }).then(data => {
				if (data.result) resolve(data.value);
				else reject(data.msg);
			});
		});
	},
	/**
	 * Создает плавающее окно
	 * По умолчанию у него display: none. Чтобы показать, $('.floating-window', $wrapper).removeClass('d-none'),
	 * где $wrapper - то, что возвращает эта функция
	 * @name уникальный идентификатор. Если уже существует, берется существующее окно.
	 * @html
	 * @top
	 * @bottom
	 * @left
	 * @right
	 * @returns {object} Обертка над окном
	 */
	getFloatingWindow: function(options){
		let $wrapper = $('.floating-window-wrapper[data-name="' + options.name + '"]');

		if (!$wrapper.length) {
			$wrapper = $(`
				<div class="floating-window-wrapper container" data-name="${options.name}">
					<div class="floating-window d-none">
						<a href="javascript:void(0)" class="float-right fas fa-times floating-window-close tum-black"></a>
						<div class="floating-window-body">
							${options.html}
						</div>
					</div>
				</div>
			`);

			let $window = $('.floating-window', $wrapper);
			if (options.top) $window.css('top', options.top);
			if (options.bottom) $window.css('bottom', options.bottom);
			if (options.left) $window.css('left', options.left);
			if (options.right) $window.css('right', options.right);
			$('body').append($wrapper);

			tumGlobal.onClickOutside($window.add(options.button), () => $window.addClass('d-none'));
		}
		
		return $wrapper;
	},
	initFloatingWindows: function(){
		addEventDelegate(document, 'click', '[data-toggle="floatingwindow"]', element => {
			if (isMob()) {
				showDialog({
                    content: `<div>${document.querySelector(element.dataset.contenttarget).innerHTML}</div>`,
                    pinToBottom: true,
					classes: "opened-as-floating",
					title: element.dataset.title
                });

				return;
			}

			let options = {
				name: element.dataset.name,
				html: document.querySelector(element.dataset.contenttarget).innerHTML,
				top: element.dataset.top,
				bottom: element.dataset.bottom,
				left: element.dataset.left,
				right: element.dataset.right,
				button: $(element)
			};

			let $windowWrapper = this.getFloatingWindow(options);
			$('.floating-window', $windowWrapper).toggleClass('d-none');
		});

		addEventDelegate(document, 'click', '.floating-window-close', element => {
			let $window = $(element).closest('.floating-window');
			$window.toggleClass('d-none');

			$('.modal.show.opened-as-floating').modal('hide');
		});
	},
	setSeoFooterCookie: function(value){
		return new Promise(resolve => ajaxSend({ url: '/home/setSeoFooterCookie', data: { value } }).then(resolve));
	},
	getFirmSchedule: function(firmID, depID){
		return new Promise(resolve => ajaxSend({ url: '/FirmRequests/GetFirmSchedule', data: { firmID: firmID, depID: depID } }).then(resolve));
	}
}

Array.prototype.diff = function (a) {
	return this.filter(function (i) {
		return a.indexOf(i) === -1;
	});
};

export default tumCommon;